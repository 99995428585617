import React from 'react';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

const Title = styled.h1`
  font-size: 40px;
`;

const Image = styled.img`
  max-height: 400px;
  display: block;
`;

const Copyright = styled.span`
  text-align: right;
  display: block;
  font-size: 13px;
  color: white;
  margin: 12px 0 32px;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 30px;
  margin-top: 32px;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

const UL = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const LI = styled.li`
  color: white;
  margin: 52px 0;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 20px;
  }
`;

function ComedyBallroom() {
  return (
    <>
      <SEO
        title="Oplev Comedy Ballroom 2022 i Odense, Skive, Herning og Aalborg"
        description="Comedy Ballroom er helt sikkert værd at opleve for dig der er til standup. Se hvor du kan opleve det i 2022."
      />
      <Layout>
        <Wrapper className="event">
          <Title className="title">
            Oplev Comedy Ballroom 2022 i Odense, Skive, Herning og Aalborg
          </Title>
          <Image
            className="event__image"
            src="http://cdn.tix.dk/tix/EventImages/Event_12921.jpg"
            alt="Comedy Ballroom 2022"
          />
          <Copyright>Billede fra Musikhuset</Copyright>
          <Subtitle>
            Comedy Ballroom præsenterer igen i år 5 af landets største navne
            indenfor standup
          </Subtitle>
          <P className="event__info_explore">
            Hvis du er til Standup comedy, er Comedy Ballroom næsten et must at
            opleve i 2022. Med Comedy Ballroom 2022 kan du september og oktober,
            opleve nogle af landets største navne indenfor stand up comedy. Det
            består nemlig af Andreas Bo, Linda P, Nikolaj Stokholm, Thomas
            Warberg og Lasse Madsen.
          </P>
          <Subtitle>Du kan opleve Comedy Ballroom 2022 her</Subtitle>
          <UL>
            <LI>
              <h2>Comedy Ballroom i Odense C</h2>
              <p>ODEON, Store Sal</p>
              <p>17. september 2022</p>
              <p>Klokken 20:00</p>
            </LI>
            <LI>
              <h2>Comedy Ballroom i Skive</h2>
              <p>Kulturcenter Skive</p>
              <p>24. september 2022</p>
              <p>Klokken 20:00</p>
            </LI>
            <LI>
              <h2>Comedy Ballroom i Herning</h2>
              <p>MCH Herning Kongrescenter</p>
              <p>30. september 2022</p>
              <p>Klokken 20:00</p>
            </LI>
            <LI>
              <h2>Comedy Ballroom i Aalborg</h2>
              <p>Aalborghallen</p>
              <p>07. oktober 2022</p>
              <p>Klokken 20:00</p>
            </LI>
          </UL>
        </Wrapper>
      </Layout>
    </>
  );
}

export default ComedyBallroom;
